import React from 'react'
import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { useBreakpoints } from '../../hooks'
// import { Button, netButtonVariants } from '../Button'
import { withErrorBoundary } from '../ErrorBoundary/ErrorBoundary.component'
import styles from './VoyoPlaceholder.style'

const PlaceholderStyled = styled.div(props => ({ ...styles(props) }))
function VoyoPlaceholder({ className, buttonHref, placeholderId, maxHeightMd, maxHeightSm, ...rest }) {
    const isAmp = useAmp()

    const [isDesktop] = useBreakpoints('md')
    const imageSrc = '/images/voyo-gospodin-savsseni.png?v=2025-01-28'

    return (
        <a
            href={buttonHref}
            target="_blank"
            className="Placeholder_container"
            id={`placeholder_container_${placeholderId}`}
            rel="noreferrer">
            <PlaceholderStyled
                maxHeight={isDesktop ? maxHeightMd : maxHeightSm}
                className={className}
                isAmp={isAmp}
                {...rest}>
                <img className="Placeholder_image" src={imageSrc} alt="VOYO logo" />
            </PlaceholderStyled>
        </a>
    )
}

VoyoPlaceholder.propTypes = {
    className: PropTypes.string,
    placeholderId: PropTypes.string,
    buttonHref: PropTypes.string,
    maxHeightMd: PropTypes.number,
    maxHeightSm: PropTypes.number
}

VoyoPlaceholder.defaultProps = {
    className: undefined,
    placeholderId: undefined,
    buttonHref: 'https://voyo.rtl.hr?utm_source=any&utm_medium=placeholder&utm_campaign=voyo',
    maxHeightMd: undefined,
    maxHeightSm: undefined
}
export default withErrorBoundary(VoyoPlaceholder, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[VoyoPlaceholder]: ', error, componentStack)
    }
})
